import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import { ComboboxField } from "@/components/molecules/Combobox";
import { countries } from "@/constants/countries";
import { useFormContext } from "react-hook-form";

const AddressForm = () => {
  const { control } = useFormContext();

  const rowClasses = "flex flex-row gap-2 w-full mt-1";

  return (
    <div className="flex flex-col w-full">
      <h2>Adresse</h2>
      <div className="flex flex-col gap-1">
        <div className={rowClasses}>
          <FormField
            control={control}
            name="address.country"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel htmlFor="country">Pays</FormLabel>
                <FormControl>
                  <ComboboxField
                    options={countries.map((country) => ({
                      value: country.libelle,
                      label: country.libelle,
                    }))}
                    placeholder={"-"}
                    value={field.value}
                    onChange={field.onChange}
                    optionnal={true}
                    id="country"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <div className={rowClasses}>
          <FormField
            control={control}
            name="address.personnalAddress"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Adresse personnelle</FormLabel>
                <FormControl>
                  <Input placeholder={"-"} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={rowClasses}>
          <FormField
            control={control}
            name="address.complement"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Complément</FormLabel>
                <FormControl>
                  <Input placeholder={"-"} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={rowClasses}>
          <FormField
            control={control}
            name="address.postalCode"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Code postal</FormLabel>
                <FormControl>
                  <Input placeholder={"-"} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="address.city"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Ville</FormLabel>
                <FormControl>
                  <Input placeholder={"-"} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
