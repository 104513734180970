import logo from "@/assets/logo.png";
import { ChevronsLeft, ChevronsRight, LogOut } from "lucide-react";
import { useState } from "react";
import { Button } from "react-day-picker";
import { useNavigate } from "react-router-dom";
import { getSession, removeSession } from "../../../../../api/auth";
import { Role } from "../../../../../api/dto/enums/Role";
import { ROUTE } from "../../../../../constants/routes";

interface LeftBarExpandedProps {
  setExpanded: (expanded: boolean) => void;
}

const LeftBarExpanded = ({ setExpanded }: LeftBarExpandedProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex w-72 flex-col flex-start justify-between h-screen flex-shrink-0 bg-brand-25 border-r border-r-gray-200 gap-8 pt-8">
      <div className="flex flex-col">
        <div className="flex flex-row pl-8 pr-4 justify-between items-center self-stretch">
          <img
            className="w-16 h-16 as"
            src={logo}
            alt="Description of the image"
          />
          <Button onClick={() => setExpanded(false)}>
            <ChevronsLeft />
          </Button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col px-4 py-6 self-stretch">
          <Button
            className="flex flex-row gap-3 items-center self-stretch p-2"
            onClick={() => {
              removeSession();
              navigate(ROUTE.admin.login);
            }}
          >
            <LogOut />
            Se déconnecter
          </Button>
        </div>
      </div>
    </div>
  );
};

interface LeftBarCollapsedProps {
  setExpanded: (expanded: boolean) => void;
}

const LeftBarCollapsed = ({ setExpanded }: LeftBarCollapsedProps) => {
  return (
    <div className="flex flex-col w-16 flex-start h-screen flex-shrink-0 bg-brand-25 border-r border-r-gray-200 gap-8 pt-8">
      <Button
        className="absolute top-2 left-10"
        onClick={() => setExpanded(true)}
      >
        <ChevronsRight className="w-4 h-4" />
      </Button>
      <div className="flex justify-center items-center self-stretch">
        <img className="w-8 h-8 as" src={logo} alt="Description of the image" />
      </div>
    </div>
  );
};

const LeftBar = () => {
  const [expanded, setExpanded] = useState(true);

  return expanded ? (
    <LeftBarExpanded setExpanded={setExpanded} />
  ) : (
    <LeftBarCollapsed setExpanded={setExpanded} />
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { role } = getSession();

  if (role !== Role.ADMIN) {
    removeSession();
    navigate(ROUTE.connectionHome);
  }

  return (
    <div className="flex flex-row bg-white">
      <LeftBar />
    </div>
  );
};

export default Dashboard;
