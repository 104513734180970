import logo from "@/assets/logo.png";
import { zodResolver } from "@hookform/resolvers/zod";
import { HttpStatusCode } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { useResetPasswordMutation } from "../../../api/auth";
import { ROUTE } from "../../../constants/routes";
import { mandatoryString, password } from "../../../constants/zodRules";
import { Button } from "../../atoms/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../atoms/Form";
import { Input } from "../../atoms/Input";
import { useToast } from "../../atoms/Toast/UseToast";
import ErrorDisplay from "../../molecules/ErrorDisplay";
import { SuccessToast } from "../../molecules/ToastsTemplates";

const resetPasswordSchema = z
  .object({
    password: password(),
    passwordConfirmation: mandatoryString(),
  })
  .superRefine(({ password, passwordConfirmation }, ctx) => {
    if (password != passwordConfirmation) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Les mots de passe ne correspondent pas",
        path: ["passwordConfirmation"],
      });
    }
  });

const PasswordChange = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const email = searchParams.get("email");

  if (!token) {
    setErrorMsg(
      "Il manque un token : l'URL que vous avez copié est incomplète"
    );
  }

  if (!email) {
    setErrorMsg(
      "Il manque un email : l'URL que vous avez copié est incomplète"
    );
  }

  const form = useForm<z.infer<typeof resetPasswordSchema>>({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });

  const resetPasswordMutation = useResetPasswordMutation({
    onSuccess: () => {
      toast({
        action: SuccessToast({
          title: "Mot de passe modifié avec succès",
          description:
            "Vous pouvez désormais vous connecter avec votre nouveau mot de passe",
        }),
        duration: 5000,
      });
      navigate(ROUTE.connectionHome);
    },
    onError: (error) => {
      switch (error.status) {
        case HttpStatusCode.Gone:
          setErrorMsg("Lien expiré ou déjà utilisé.");
          return;

        case HttpStatusCode.Unauthorized:
          setErrorMsg("Lien invalide.");
          return;

        case HttpStatusCode.InternalServerError:
          setErrorMsg(
            "Le serveur a rencontré une erreur lors de la réinitialisation du mot de passe."
          );
          return;

        default:
          setErrorMsg(
            "Une erreur est survenue lors de la réinitialisation du mot de passe."
          );
          return;
      }
    },
  });

  function onSubmit(values: z.infer<typeof resetPasswordSchema>) {
    const { data, error, success } = resetPasswordSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting password reset :", error);
      return;
    }

    resetPasswordMutation.mutate({
      token: token as string,
      data: {
        password: data.password,
        email: email as string,
      },
    });
  }

  return (
    <div className="flex flex-col items-center mt-16 h-screen">
      <img
        className="flex justify-center items-center mb-12"
        src={logo}
        alt="Description of the image"
      />
      <div className="flex w-96 gap-8 flex-col items-start p-10 rounded-lg border border-gray-200 bg-white shadow-sm">
        <h1>Mot de passe oublié</h1>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col space-y-6"
          >
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Nouveau mot de passe</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"********"}
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="passwordConfirmation"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Confirmation du nouveau mot de passe</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"********"}
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="flex px-1 py-2 justify-center gap-1 self-stretch rounded-lg border border-[#1D64D8] bg-[#2570EB] mt-7">
              Confirmer
            </Button>
            {resetPasswordMutation.isError && <ErrorDisplay msg={errorMsg} />}
          </form>
        </Form>
      </div>
    </div>
  );
};

export default PasswordChange;
