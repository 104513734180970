import { z } from "zod";
import { optionnalString } from "../../../../../constants/zodTypes";

const addressSchema = z.object({
  country: optionnalString(),
  personnalAddress: optionnalString(),
  complement: optionnalString(),
  postalCode: optionnalString(),
  city: optionnalString(),
});

const addressDefaultValues: z.infer<typeof addressSchema> = {
  country: "",
  personnalAddress: "",
  complement: "",
  postalCode: "",
  city: "",
};

export { addressDefaultValues, addressSchema };
