export const ROUTE = {
  home: "/",
  connectionHome: "/home",
  admin: {
    login: "/admin/login",
    dashboard: "/admin/dashboard",
  },
  candidate: {
    accountCreation: "/candidate/account-creation",
    myExams: "/candidate/my-exams",
  },
  forgotten: {
    password: {
      ask: "/forgotten/password/ask",
      reset: "/forgotten/password/reset",
    },
    email: {
      ask: "/forgotten/email/ask",
    },
  },
};
