import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { z } from "zod";
import { Role } from "../../api/dto/enums/Role";
import { LoginResponse } from "../../api/dto/login.dto";
import { email, password } from "../../constants/zodTypes";
import { storeSession } from "../../api/auth";

const connectionSchema = z.object({
  email: email(),
  password: password(),
});

const onSuccessConnect = (response: AxiosResponse<LoginResponse>): Role => {
  const { token, role } = response.data;
  storeSession(token, role);

  return role;
};

const onErrorConnect = (error: AxiosError): string => {
  switch (error.status) {
    case HttpStatusCode.Unauthorized:
      return "Identifiants incorrects.";

    case HttpStatusCode.InternalServerError:
      return "Le serveur a rencontré une erreur lors de la connexion.";

    default:
      return "Une erreur est survenue lors de la connexion.";
  }
};

export { connectionSchema, onErrorConnect, onSuccessConnect };
