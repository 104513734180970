import logo from "@/assets/logo.png";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useLogInMutation } from "../../../../api/auth";
import { Role } from "../../../../api/dto/enums/Role";
import { ROUTE } from "../../../../constants/routes";
import { Button } from "../../../atoms/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../atoms/Form";
import { Input } from "../../../atoms/Input";
import { useToast } from "../../../atoms/Toast/UseToast";
import {
  connectionSchema,
  onErrorConnect,
  onSuccessConnect,
} from "../../../molecules/ConnectionForm";
import ErrorDisplay from "../../../molecules/ErrorDisplay";

const Login = () => {
  const form = useForm<z.infer<typeof connectionSchema>>({
    resolver: zodResolver(connectionSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { toast } = useToast();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const control = form.control;

  const loginMutation = useLogInMutation({
    onSuccess: async (response) => {
      const { role } = response.data;

      if (role !== Role.ADMIN) {
        setErrorMsg("Ce compte n'a pas le rôle administrateur.");
        return;
      }

      onSuccessConnect(response);

      navigate(ROUTE.admin.dashboard);
      toast({
        title: "Connexion réussie.",
        description: "Vous pouvez accéder à votre compte.",
        duration: 5000,
      });
    },
    onError: (error) => {
      setErrorMsg(onErrorConnect(error));
    },
  });

  function onSubmit(values: z.infer<typeof connectionSchema>) {
    const { data, error, success } = connectionSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting login :", error);
      return;
    }

    loginMutation.mutate({
      username: data.email,
      password: data.password,
    });
  }

  return (
    <div className="flex flex-col items-center mt-16 h-screen">
      <img
        className="flex justify-center items-center mb-12"
        src={logo}
        alt="Description of the image"
      />
      <div className="flex w-96 flex-col items-start p-10 gap-8 rounded-lg border border-gray-200 bg-white shadow-sm">
        <h1>Se connecter</h1>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col space-y-6"
          >
            <FormField
              control={control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Adressse email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"mail@mail.com"}
                      {...field}
                      autoComplete="email"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Mot de passe</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"********"}
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center flex-col justify-center gap-2 self-stretch">
              <Button
                className="flex font-semibold justify-center items-center self-stretch bg-transparent text-base text-brand-800 focused hover:bg-transparent hover:text-brand-500"
                onClick={() => navigate(ROUTE.forgotten.password.ask)}
              >
                Mot de passe oublié
              </Button>
              <Button
                className="flex px-1 py-2 mt-3 justify-center gap-1 self-stretch rounded-lg border border-[#1D64D8] bg-[#2570EB] font-semibold text-base"
                type="submit"
                disabled={loginMutation.isPending}
              >
                {loginMutation.isPending
                  ? "Connection en cours..."
                  : "Se connecter"}
              </Button>
              <div className="flex flex-col self-stretch mt-4">
                {errorMsg && <ErrorDisplay msg={errorMsg} />}
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default Login;
