import { Sex } from "@/api/dto/enums/Sex";
import { z } from "zod";
import {
  date,
  email,
  mandatoryString,
  optionalPhone,
  optionnalString,
  password,
} from "../../../../../constants/zodTypes";

const personnalInfoSchema = z
  .object({
    sex: z.enum([Sex.MALE, Sex.FEMALE]),
    lastName: mandatoryString(),
    firstName: mandatoryString(),
    // minimum 18 years old, maximum 100 years old
    birthdate: date(
      new Date(new Date().getTime() - 100 * 365 * 24 * 60 * 60 * 1000),
      new Date(new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000)
    ),
    birthCity: mandatoryString().transform(
      (city) => city[0].toUpperCase() + city.slice(1).toLowerCase()
    ),
    birthCountry: optionnalString(),
    nationality: mandatoryString(),
    email: email(),
    emailConfirmation: email(),
    password: password(),
    passwordConfirmation: z.string(),
    homePhone: optionalPhone(),
    mobilePhone: optionalPhone(),
  })
  .superRefine(
    ({ password, passwordConfirmation, email, emailConfirmation }, ctx) => {
      if (password != passwordConfirmation) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Les mots de passe ne correspondent pas",
          path: ["passwordConfirmation"],
        });
      }

      if (email != emailConfirmation) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Les adresses mail ne correspondent pas",
          path: ["emailConfirmation"],
        });
      }
    }
  );

const personnalInfoDefaultValues: z.infer<typeof personnalInfoSchema> = {
  sex: Sex.FEMALE,
  lastName: "",
  firstName: "",
  birthdate: new Date(),
  birthCity: "",
  birthCountry: "",
  nationality: "",
  email: "",
  emailConfirmation: "",
  password: "",
  passwordConfirmation: "",
  homePhone: "",
  mobilePhone: "",
};

export { personnalInfoDefaultValues, personnalInfoSchema };
