import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { AskChangePasswordDto } from "./dto/ask-change-password.dto";
import { ChangePasswordDto } from "./dto/change-password.dto";
import { Role } from "./dto/enums/Role";
import { LoginDto, LoginResponse } from "./dto/login.dto";
import { RegisterCandidateDto } from "./dto/register-candidate.dto";

const keyFactory = {
  getRole: () => ["getRole"],
};

const logIn = async (data: LoginDto) => {
  return await axios.post<LoginResponse>(`/auth/login`, data);
};

export const useLogInMutation = (
  props?: UseMutationOptions<AxiosResponse<LoginResponse>, AxiosError, LoginDto>
) => {
  return useMutation({
    mutationFn: logIn,
    ...props,
  });
};

const registerCandidate = async (data: RegisterCandidateDto) => {
  return await axios.post<void>(`/auth/register/candidate`, data);
};

export const useRegisterUserMutation = (
  props?: UseMutationOptions<
    AxiosResponse<void>,
    AxiosError,
    RegisterCandidateDto
  >
) => {
  return useMutation({
    mutationFn: registerCandidate,
    ...props,
  });
};

const getMyRole = async () => {
  return await axios.get<Role>(`/auth/role`);
};

export const useGetMyRoleQuery = (
  props?: UseQueryOptions<AxiosResponse<Role>, AxiosError, AxiosResponse<Role>>
) => {
  return useQuery({
    queryKey: keyFactory.getRole(),
    queryFn: getMyRole,
    ...props,
  });
};

const askChangePassword = async (data: AskChangePasswordDto) => {
  return await axios.post(`/auth/ask-change-password`, data);
};

export const useAskChangePasswordMutation = (
  props?: UseMutationOptions<
    AxiosResponse<void>,
    AxiosError,
    AskChangePasswordDto
  >
) => {
  return useMutation({
    mutationFn: askChangePassword,
    ...props,
  });
};

const changePassword = async (data: ChangePasswordDto, token: string) => {
  return await axios.post(`/auth/change-password`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useResetPasswordMutation = (
  props?: UseMutationOptions<
    AxiosResponse,
    AxiosError,
    {
      data: ChangePasswordDto;
      token: string;
    }
  >
) => {
  return useMutation({
    mutationFn: ({ data, token }: { data: ChangePasswordDto; token: string }) =>
      changePassword(data, token),
    ...props,
  });
};

export const storeSession = (token: string, role: Role) => {
  // store token in local storage
  localStorage.setItem("token", token);
  localStorage.setItem("role", role);
  // the token will be used automatically by axios
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("role");
  axios.defaults.headers.common.Authorization = "";
};

export const getSession = () => {
  return {
    token: localStorage.getItem("token"),
    role: localStorage.getItem("role"),
  };
};

const helloWorld = async () => {
  return await axios.get<string>(`/`);
};

export const useHelloWorldQuery = () => {
  return useQuery({
    queryKey: ["hello"],
    queryFn: helloWorld,
  });
};
