import { isValidPhoneNumber } from "react-phone-number-input";
import { z } from "zod";

const mandatoryString = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .max(50, { message: "50 caractères maximum" })
    .trim();

const optionnalString = () => mandatoryString().or(z.literal(""));

const email = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .email({ message: "Veuillez entrer une adresse email valide" })
    .trim();

const password = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .min(8, { message: "Le mot de passe doit contenir au moins 8 caractères" })
    .max(50, {
      message: "Le mot de passe doit contenir au plus 100 caractères",
    })
    .trim()
    .refine(
      (password) => {
        // backend DTO applies the same rules
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        return hasUppercase && hasLowercase && hasNumber && hasSymbol;
      },
      {
        message:
          'Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un symbole (!@#$%^&*(),.?":{}|<></>)',
      }
    );

const optionalPhone = () =>
  z.string().refine((phone) => phone == "" || isValidPhoneNumber(phone), {
    message: "Le numéro de téléphone doit être valide",
  });

const file = (accepted_types: string[], max_size: number) =>
  z
    .custom<File>()
    .refine((file: File) => file.size > 0, {
      message: "Fichier manquant",
    })
    .refine((file: File) => accepted_types.includes(file.type), {
      message: `Format invalide : seulement les formats ${accepted_types
        .map((type) => type.split("/")[1])
        .join(", ")} sont acceptés`,
    })
    .refine((file: File) => file.size <= max_size, {
      message: `Fichier trop grand. Taille maximale autorisée : ${
        max_size / (1024 * 1024)
      } MB`,
    });

const date = (minDate: Date, maxDate: Date) =>
  z
    .date({ message: "Mauvais format (attendu JJ/MM/AAAA)" })
    .min(minDate, {
      message: `Veuillez choisir date postérieur au ${minDate.toLocaleDateString(
        "fr"
      )}`,
    })
    .max(
      maxDate,
      `Veuillez choisir date antérieure au ${maxDate.toLocaleDateString("fr")}`
    );

export {
  email,
  mandatoryString,
  optionnalString,
  password,
  optionalPhone,
  file,
  date,
};
