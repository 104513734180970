import { AlertCircle } from "lucide-react";

const SuccessToast = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className="flex items-start flex-col gap-3 ">
      <AlertCircle className="h-6 w-6 text-green-500 mr-2" />

      <div>
        <h3 className="font-bold">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export { SuccessToast };
