import { z } from "zod";

const mandatoryString = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .max(50, { message: "50 caractères maximum" })
    .trim();

const optionnalString = () => mandatoryString().or(z.literal(""));

const password = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .min(8, { message: "Le mot de passe doit contenir au moins 8 caractères" })
    .max(50, {
      message: "Le mot de passe doit contenir au plus 100 caractères",
    })
    .trim()
    .refine(
      (password) => {
        // backend DTO applies the same rules
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        return hasUppercase && hasLowercase && hasNumber && hasSymbol;
      },
      {
        message:
          'Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un symbole (!@#$%^&*(),.?":{}|<></>)',
      }
    );

export { mandatoryString, optionnalString, password };
