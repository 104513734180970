"use client";

import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/atoms/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/atoms/Command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/Popover";
import { Option } from "react-day-picker";
import { useFormField } from "../atoms/Form";

type Option = {
  value: string;
  label: string;
};

interface ComboboxFieldProps {
  options: Option[];
  placeholder: string;
  value?: string; // To bind with react-hook-form
  onChange: (value: string) => void; // To bind with react-hook-form
  id?: string;
  optionnal?: boolean;
  disabled?: boolean;
  className?: string;
}

export function ComboboxField({
  options,
  placeholder,
  value,
  disabled = false,
  onChange,
  id,
  optionnal = false,
  className = "",
}: ComboboxFieldProps) {
  const [open, setOpen] = React.useState(false);
  const { error } = useFormField();

  // PopoverContent same width as trigger :
  // https://github.com/shadcn-ui/ui/issues/1690#issuecomment-2016635419

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            "w-full justify-between disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50",
            error ? "border-red-500 border" : "border-gray-300",
            className
          )}
          id={id}
          disabled={disabled}
        >
          {value
            ? options.find((option) => option.value === value)?.label
            : placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height]">
        <Command>
          <CommandInput placeholder="Rechercher..." />
          <CommandList>
            <CommandEmpty>Aucun résultat.</CommandEmpty>
            <CommandGroup>
              {
                // add null option if optionnal
                [
                  ...(optionnal ? [{ value: "", label: "-" }] : []),
                  ...options,
                ].map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={(currentValue) => {
                      onChange(currentValue === value ? "" : currentValue); // Notify react-hook-form of the change
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value === option.value ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {option.label}
                  </CommandItem>
                ))
              }
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
