"use client";

import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { Button } from "@/components/atoms/Button";
import { Calendar } from "@/components/atoms/Calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/Popover";
import { cn } from "@/lib/utils";
import { FormControl, useFormField } from "../atoms/Form";

interface DatePickerProps {
  value: Date;
  onChange: (date: Date) => void;
  restrictToToday?: boolean;
}

const DatePicker = ({
  value,
  onChange,
  restrictToToday = false,
}: DatePickerProps) => {
  const { error } = useFormField();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant={"outline"}
            className={cn(
              "w-full border-gray-300 justify-start text-left font-normal",
              !value && "text-muted-foreground",
              error && "border-red-500 border"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {value ? format(value, "dd/MM/yyyy") : <span>DD/MM/YYYY</span>}
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          required={true}
          mode="single"
          selected={value}
          onSelect={onChange}
          disabled={(date) =>
            restrictToToday &&
            (date > new Date() || date < new Date("1900-01-01"))
          }
        />
      </PopoverContent>
    </Popover>
  );
};

export default DatePicker;
