import logo from "@/assets/logo.png";
import { Button } from "@/components/atoms/Button";
import BackButton from "@/components/molecules/BackButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { LucideChevronLeft } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { useRegisterUserMutation } from "../../../../api/auth";
import { ROUTE } from "../../../../constants/routes";
import { Form } from "../../../atoms/Form";
import { useToast } from "../../../atoms/Toast/UseToast";
import ErrorDisplay from "../../../molecules/ErrorDisplay";
import { SuccessToast } from "../../../molecules/ToastsTemplates";
import AddressForm from "./AddressForm/AddressForm";
import {
  addressDefaultValues,
  addressSchema,
} from "./AddressForm/AddressFormSchema";
import ForeignCertificateForm from "./ForeignCertificateForm/ForeignCertificateForm";
import PersonnalInformation from "./PersonnalInfoForm/PersonnalInfoForm";
import {
  personnalInfoDefaultValues,
  personnalInfoSchema,
} from "./PersonnalInfoForm/PersonnalInfoFormSchema";
import { HttpStatusCode } from "axios";

const accountCreationSchema = z.object({
  address: addressSchema,
  personnalInfo: personnalInfoSchema,
});

const TopText = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-1 items-start">
      <Button
        className="p-0 m-0 bg-transparent text-gray-800 gap-1.5 hover:text-brand-700 hover:bg-transparent text-xs"
        onClick={() => navigate(-1)}
      >
        <LucideChevronLeft />
        Retour à la page précédente
      </Button>
      <h1 className="mt-1.5">Création du compte</h1>
      <p className="mt-1">
        Complétez les différents champs pour la création de votre compte.
        <span className="text-brand-600 font-bold">
          {" "}
          Les champs marqués d'un astérisque *{" "}
        </span>
        figureront sur votre certificat : ils doivent être soigneusement
        vérifiés et correctement renseignés.
      </p>
    </div>
  );
};

const AccountCreation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state)
      navigate(ROUTE.connectionHome, { state: { hasCertificate: false } });
  });

  const hasCertificate = location.state?.hasCertificate ?? (false as boolean);
  const { toast } = useToast();
  const [errorMsg, setErrorMsg] = useState("");

  const registerUserMutation = useRegisterUserMutation({
    onSuccess: () => {
      navigate(ROUTE.connectionHome);
      toast({
        action: SuccessToast({
          title: "Votre compte a bien été créé",
          description: "Vous pouvez maintenant y accéder",
        }),
      });
    },
    onError: (error) => {
      switch (error.status) {
        case HttpStatusCode.Conflict:
          setErrorMsg("Cet email est déjà utilisé.");
          console.error(error.response?.data);
          return;

        case HttpStatusCode.InternalServerError:
          setErrorMsg(
            "Le serveur a rencontré une erreur lors de la création de votre compte."
          );
          return;

        default:
          setErrorMsg(
            "Une erreur est survenue lors de la création de votre compte."
          );
          return;
      }
    },
  });

  const form = useForm<z.infer<typeof accountCreationSchema>>({
    resolver: zodResolver(accountCreationSchema),
    defaultValues: {
      address: addressDefaultValues,
      personnalInfo: personnalInfoDefaultValues,
    },
  });

  function onSubmit(values: z.infer<typeof accountCreationSchema>) {
    const { data, error, success } = accountCreationSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting registration :", error);
      return;
    }

    const pi = data.personnalInfo;
    const address = data.address;

    registerUserMutation.mutate({
      email: pi.email,
      password: pi.password,
      sex: pi.sex,
      lastName: pi.lastName,
      firstName: pi.firstName,
      birthdate: pi.birthdate,
      birthCity: pi.birthCity,
      nationality: pi.nationality,
      ...(pi.birthCountry && { birthCountry: pi.birthCountry }),
      ...(pi.homePhone && { homePhone: pi.homePhone }),
      ...(pi.mobilePhone && { mobilePhone: pi.mobilePhone }),
      ...(address.country && {
        personnalAddress: address.personnalAddress,
        postalCode: address.postalCode,
        city: address.city,
        complement: address.complement,
        country: address.country,
      }),
    });
  }

  return (
    <div className="flex flex-col items-center mt-16 min-h-screen">
      <BackButton />
      <img
        className="flex justify-center items-center mb-12"
        src={logo}
        alt="Description of the image"
      />
      <div className="max-w-3xl flex flex-col items-start px-10 py-8 rounded-lg border border-gray-200 bg-white shadow-sm gap-8 mb-32">
        <TopText />
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col space-y-8"
          >
            <PersonnalInformation />
            {hasCertificate && <ForeignCertificateForm />}
            <AddressForm />
            <Button
              className="flex w-full font-semibold bg-brand-600 text-white"
              type="submit"
              disabled={registerUserMutation.isPending}
            >
              {registerUserMutation.isPending
                ? "Création de votre compte..."
                : "Créer mon compte"}
            </Button>
            {registerUserMutation.isError && <ErrorDisplay msg={errorMsg} />}
          </form>
        </Form>
      </div>
    </div>
  );
};

export default AccountCreation;
