import { zodResolver } from "@hookform/resolvers/zod";
import { LucideInfo } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useLogInMutation } from "../../../api/auth";
import { Role } from "../../../api/dto/enums/Role";
import { ROUTE } from "../../../constants/routes";
import { Button } from "../../atoms/Button";
import ButtonMailto from "../../atoms/ButtonMailTo";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../atoms/Form";
import { Input } from "../../atoms/Input";
import { useToast } from "../../atoms/Toast/UseToast";
import {
  connectionSchema,
  onErrorConnect,
  onSuccessConnect,
} from "../../molecules/ConnectionForm";
import ErrorDisplay from "../../molecules/ErrorDisplay";
import { SuccessToast } from "../../molecules/ToastsTemplates";

const Connect = () => {
  const form = useForm<z.infer<typeof connectionSchema>>({
    resolver: zodResolver(connectionSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { toast } = useToast();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const control = form.control;

  const postLoginRedirections = {
    [Role.CANDIDATE]: ROUTE.candidate.myExams,
    [Role.COMPANY]: ROUTE.home,
    [Role.ADMIN]: ROUTE.home,
  };

  const loginMutation = useLogInMutation({
    onSuccess: async (response) => {
      const role = onSuccessConnect(response);
      const redirection = postLoginRedirections[role as Role];
      navigate(redirection);
      toast({
        action: SuccessToast({
          title: "Connexion réussie",
          description: "Vous pouvez accéder à votre compte",
        }),
        duration: 5000,
      });
    },
    onError: (error) => {
      setErrorMsg(onErrorConnect(error));
    },
  });

  function onSubmit(values: z.infer<typeof connectionSchema>) {
    const { data, error, success } = connectionSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting login :", error);
      return;
    }

    loginMutation.mutate({
      username: data.email,
      password: data.password,
    });
  }

  return (
    <div className="items-center">
      <div className="flex self-stretch flex-row p-4 mt-8 bg-white rounded-xl shadow border border-gray-300 justify-start items-start gap-4">
        <div className="flex self-stretch">
          <LucideInfo className="w-5 h-5 text-brand-600" />
        </div>
        <div className="flex flex-col gap-1 pr-10">
          <div className="text-slate-800 text-sm font-semibold leading-6">
            Vous avez déjà un compte candidat CIFMD si vous avez déjà passé au
            moins un examen ou vous avez une inscription en cours pour un examen
          </div>
          <div className="text-slate-600 text-sm font-normal leading-6">
            Attention, si vous avez déjà créé un compte mais que vous n'arrivez
            plus à vous connecter, cliquez sur : "Je n'ai plus accès à mes
            identifiants" ou contactez notre support{" "}
            <span className="underline">
              <ButtonMailto
                label="contact@cifmd.fr"
                mailto="mailto:contact@cifmd.fr"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start gap-6 self-stretch mt-8">
        <h1>Connexion</h1>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col space-y-6"
          >
            <FormField
              control={control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Adressse email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"mail@mail.com"}
                      {...field}
                      autoComplete="email"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Mot de passe</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={"********"}
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center flex-col justify-center gap-2 self-stretch">
              <Button
                className="flex font-semibold justify-center items-center self-stretch bg-transparent text-base text-brand-800 focused hover:bg-transparent hover:text-brand-500"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(ROUTE.forgotten.password.ask);
                }}
              >
                Mot de passe oublié
              </Button>
              <Button
                className="flex font-semibold justify-center items-center self-stretch bg-transparent text-base text-brand-800 focused hover:bg-transparent hover:text-brand-500"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(ROUTE.forgotten.email.ask);
                }}
                disabled={true}
                type="button"
              >
                Je n'ai plus accès à mes identifiants
              </Button>
              <Button
                className="flex px-1 py-2 mt-3 justify-center gap-1 self-stretch rounded-lg border border-[#1D64D8] bg-[#2570EB] font-semibold text-base"
                type="submit"
                disabled={loginMutation.isPending}
              >
                {loginMutation.isPending
                  ? "Connection en cours..."
                  : "Se connecter"}
              </Button>
              <div className="flex flex-col self-stretch mt-4">
                {loginMutation.isError && <ErrorDisplay msg={errorMsg} />}
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default Connect;
