import logo from "@/assets/logo.png";
import { Link } from "react-router-dom";
import { ROUTE } from "../../constants/routes";
import { useHelloWorldQuery } from "../../api/auth";

const Welcome = () => {
  const helloWorld = useHelloWorldQuery();

  return (
    <div className="flex flex-col items-center mt-16 h-screen">
      <img
        className="flex justify-center items-center mb-12"
        src={logo}
        alt="Description of the image"
      />
      <div className="flex w-96 flex-col items-start p-10 gap-8 rounded-lg border border-gray-200 bg-white shadow-sm">
        <div>
          <h1>Site de test.</h1>
          <br />
          Si cette page s'affiche, c'est que le serveur frontend est en ligne :)
        </div>
        <div>
          <h2>Test de la connexion</h2>
          Le serveur backend est-il en ligne ? →{" "}
          {helloWorld.isLoading
            ? "Chargement..."
            : helloWorld.isError
            ? "Erreur : " + helloWorld.error.message
            : "Oui !"}
        </div>
        <div className="flex flex-col w-full">
          <h2>Test des pages</h2>
          <ul className="flex flex-col gap-2 mt-3">
            {[
              { path: ROUTE.connectionHome, msg: "Accueil" },
              { path: ROUTE.admin.login, msg: "Login admin" },
              { path: ROUTE.admin.dashboard, msg: "Dashboard admin" },
            ].map(({ path, msg }) => (
              <li
                key={path}
                className="flex w-full items-center justify-center"
              >
                <Link
                  className="flex w-full border items-center justify-center rounded-lg bg-brand-500 border-brand-700 text-white hover:bg-brand-600 hover:text-white py-2"
                  to={path}
                >
                  {msg}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
