import { LucideLogOut } from "lucide-react";
import { Button } from "react-day-picker";
import { useNavigate } from "react-router-dom";
import { removeSession } from "../../../../api/auth";
import { ROUTE } from "../../../../constants/routes";

const TopBar = () => {
  const navigate = useNavigate();

  return (
    <div className="flex py-2 px-20 justify-between items-center self-stretch bg-white">
      <div></div>
      <div className="flex items-center gap-6">
        <Button
          className="flex flex-row py-2 px-3 justify-center items-center gap-1 rounded-lg border border-gray-300 bg-white text-gray-800 hover:bg-gray-10"
          onClick={() => {
            removeSession();
            navigate(ROUTE.connectionHome);
          }}
        >
          <LucideLogOut />
          <span className="font-semibold leading-6">Se déconnecter</span>
        </Button>
      </div>
    </div>
  );
};

const MyExams = () => {
  return (
    <div>
      <TopBar />
      TODO
    </div>
  );
};

export default MyExams;
