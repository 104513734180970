import logo from "@/assets/logo.png";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../atoms/Tabs";
import BackButton from "../../molecules/BackButton";
import CandidateCreateAccount from "./CandidateCreateAccount";
import CompanyCreateAccount from "./CompanyCreateAccount";
import Connect from "./Connect";

const Home = () => {
  return (
    <div className="flex flex-col items-center mt-16 h-screen">
      <BackButton />
      <img
        className="flex justify-center items-center mb-12"
        src={logo}
        alt="Description of the image"
      />
      <div className="flex max-w-xl flex-col items-start p-8 rounded-lg border border-gray-200 bg-white shadow-sm">
        <Tabs defaultValue="connect">
          <TabsList className="flex px-0 py-0.5">
            <TabsTrigger
              value="connect"
              className="flex m-0.5 w-full h-full items-center justify-center"
            >
              Déjà un compte
            </TabsTrigger>
            <TabsTrigger
              value="register"
              className="flex m-0.5 w-full h-full items-center justify-center"
            >
              Créer un compte
            </TabsTrigger>
          </TabsList>
          <TabsContent value="connect">
            <Connect />
          </TabsContent>
          <TabsContent value="register">
            <Tabs defaultValue="candidate">
              <TabsList className="bg-transparent">
                <TabsTrigger
                  className="rounded-none data-[state=active]:bg-transparent data-[state=active]:text-blue-700 border-b data-[state=active]:border-b-2 data-[state=active]:border-blue-700"
                  value="candidate"
                >
                  Je suis un candidat
                </TabsTrigger>
                <TabsTrigger
                  value="company"
                  className="rounded-none data-[state=active]:bg-transparent data-[state=active]:text-blue-700 border-b data-[state=active]:border-b-2 data-[state=active]:border-blue-500"
                  disabled={true}
                >
                  Je suis une entreprise
                </TabsTrigger>
              </TabsList>
              <TabsContent value="candidate">
                <CandidateCreateAccount />
              </TabsContent>
              <TabsContent value="company">
                <CompanyCreateAccount />
              </TabsContent>
            </Tabs>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Home;
