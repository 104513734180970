import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "unfonts.css";
import { Toaster } from "./components/atoms/Toast/Toaster.tsx";
import Dashboard from "./components/pages/Admin/Login/Dashboard/Dashboard.tsx";
import Login from "./components/pages/Admin/Login/Login.tsx";
import AccountCreation from "./components/pages/Candidate/AccountCreation/AccountCreation.tsx";
import MyExams from "./components/pages/Candidate/MyExams/MyExams.tsx";
import Home from "./components/pages/ConnectionHome/ConnectionHome.tsx";
import ForgottenEmail from "./components/pages/ForgottenCredentials/ForgottenEmail.tsx";
import ForgottenPassword from "./components/pages/ForgottenCredentials/ForgottenPassword.tsx";
import PasswordChange from "./components/pages/ForgottenCredentials/PasswordChange.tsx";
import NotFound from "./components/pages/NotFound.tsx";
import Welcome from "./components/pages/Welcome.tsx";
import { ROUTE } from "./constants/routes.tsx";
import "./styles/index.css";

const API_URL = import.meta.env.VITE_API_URL;
const API_PORT = import.meta.env.VITE_API_PORT;

axios.defaults.baseURL = `${API_URL}:${API_PORT}`;

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: ROUTE.home,
    element: <Welcome />,
  },
  {
    path: ROUTE.admin.login,
    element: <Login />,
  },
  {
    path: ROUTE.connectionHome,
    element: <Home />,
  },
  {
    path: ROUTE.candidate.accountCreation,
    element: <AccountCreation />,
  },
  {
    path: ROUTE.candidate.myExams,
    element: <MyExams />,
  },
  { path: ROUTE.forgotten.password.reset, element: <PasswordChange /> },
  { path: ROUTE.forgotten.email.ask, element: <ForgottenEmail /> },
  { path: ROUTE.forgotten.password.ask, element: <ForgottenPassword /> },
  { path: ROUTE.admin.dashboard, element: <Dashboard /> },
  {
    path: "*",
    element: <NotFound />,
  },
]);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster />
    </QueryClientProvider>
  </StrictMode>
);
